<template>
    <div>
      <el-container>
        <el-header style="padding: 0" height="100%">
          <vheader class="hidden-sm-and-down"></vheader>
          <ivheadera class="hidden-md-and-up"></ivheadera>
        </el-header>
        <el-main style="padding: 0">
          <div class="code-page">
            <div class="header-title">
<!--              <p>SPORTS Y FUN阳光有氧</p>-->
<!--              <p> 双人瑜伽之旅</p>-->
            </div>
            <div class="content">
              <div v-show="boxShow === 1" class="box1">
                <div class="sign">
                  <img src="../../assets/images/G/codeIcon.png" alt="">
                  <span class="txt">输入票码验证入场</span>
                </div>
                <el-form class="ticket-form" :model="form" ref="form" :rules="rules">
                  <el-form-item prop="ticket">
                    <el-input clearable  class="input" placeholder="输入用户票码" v-model="form.ticket"></el-input>
                  </el-form-item>
                  <el-form-item class="phone-btn">
                    <el-button class="confirm" @click="confirm">确认输入</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div v-show="boxShow === 2" class="box2">
                <i class="el-icon-circle-check" style="color: #a5cd39"></i>
                <p>票码正确，验证成功</p>
                <button><span>{{count}}</span>秒后返回验证界面</button>
              </div>
              <div v-show="boxShow === 3" class="box2">
                <i class="el-icon-warning-outline"  style="color: #ffa300"></i>
                <p>票码成功，但非首次验证</p>
                <button><span>{{count}}</span>秒后返回验证界面</button>
              </div>
              <div v-show="boxShow === 4" class="box2">
                <i class="el-icon-circle-close"  style="color: #f00"></i>
                <p>票码不正确，请检查票码</p>
                <button><span>{{count}}</span>秒后返回验证界面</button>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
</template>
<script>
import { checkCode, shstate } from '../../api'
import qs from 'qs'
import axios from 'axios'

export default {
  data () {
    return {
      boxShow: 1,
      form: {
        ticket: ''
      },
      timer: null,
      count: 5,
      rules: {
        ticket: [
          { required: true, message: '请输入票码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    confirm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // axios.post('http://www.cityplus.com/FH-WEB/webg/checkcode?code=' + this.form.ticket).then(res => {
          checkCode(this.form.ticket).then(res => {
            console.log(res)
            if (res.status === 200) {
              if (res.data.length == 0) {
                this.boxShow = 4
                this.countDown()
              } else {
                if (res.data[0].STATE === 'false') {
                  this.boxShow = 2
                  let data = {
                    C: this.form.ticket
                  }
                  shstate(qs.stringify(data)).then(res => {
                  })
                } else {
                  this.boxShow = 3
                }
                this.countDown()
              }
              // console.log(res.data[0]['count(C)'])
              // if (res.data[0]['count(C)'] === 1) {
              //   this.boxShow = 2
              //   this.countDown()
              // } else if (res.data[0]['count(C)'] === 0) {
              // }
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    countDown () {
      let timeCount = 5
      if (!this.timer) {
        this.count = timeCount
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= timeCount) {
            this.count--
          } else {
            this.count = 5
            clearInterval(this.timer)
            this.timer = null
            this.boxShow = 1
            this.form.ticket = ''
            WeixinJSBridge.call('closeWindow')
          }
        }, 1000)
      }
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('请先登录Cityplus账号，然后启用微信扫一扫功能进行扫码验证', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        closeOnClickModal: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      if (this.$route.query.code != undefined) {
        checkCode(this.$route.query.code).then(res => {
          console.log(res)
          if (res.status === 200) {
            if (res.data.length == 0) {
              this.boxShow = 4
              this.countDown()
            } else {
              if (res.data[0].STATE === 'false') {
                this.boxShow = 2
                let data = {
                  C: this.form.ticket
                }
                shstate(qs.stringify(data)).then(res => {
                })
              } else {
                this.boxShow = 3
              }
              this.countDown()
            }
          }
        })
      }
    }
  }
}
</script>
<style>
.code-page .el-input__inner{
  text-align: center;
  border-radius: 0;
}
</style>
<style scoped lang="less">
.code-page{
  max-width: 992px;
  margin: 1.1rem auto 0 auto;
  background: #f4f4f4;
  .header-title{
    padding: 1.5rem 0.7rem;
    background: #fff;
    p{
      text-align: left;
      color: #474747;
      font-size: 0.4rem;
    }
  }
  .content{
    padding: 1.1rem 0.7rem 0 0.7rem;
    background: #fff;
    .sign{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 0.888rem;
      }
      .txt{
        font-size: 0.555rem;
        color: #474747;
        margin-left: 0.5rem;
      }
    }
    .ticket-form{
      margin-top: 1rem;
      .confirm{
        margin-top: 0.2rem;
        width: 100%;
        background: #a5cd39;
        color: #fff;
        border-color: #a5cd39;
        border-radius: 0;
      }
    }
    .box2{
      i{
        margin-top: 0.5rem;
        font-size: 1rem;
      }
      p{
        margin-top: 0.33rem;
        font-size: 0.44rem;
        color: #474747;
      }
      button{
        width: 100%;
        padding: 0.25rem 0;
        margin-top: 0.55rem;
        border: 1px solid #a5cd39;
        background: #a5cd39;
        color: #fff;
        font-size: 0.333rem;
      }
    }
  }
}
</style>
